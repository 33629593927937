@import "./../global/varibale";
@import "./../global/mixin";

@mixin commonLabelProperty {
  .input-group-element {
    padding-top: 20px;
    padding-bottom: 10px;

    @include break-point(mobile-view) {
      padding-bottom: 0;
    }

    .input-element {
      padding-left: 10px;
      padding-top: 6px;

      @include break-point(mobile-view) {
        height: 36px;
      }
    }
  }
}

.form-element {
  margin-bottom: 8px;

  .input-element {
    border-width: 0;
    box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, .45);
    border-radius: 5px;
    color: $font-default-color;
    font-family: VoltePlay_Medium, sans-serif;
    font-size: 16px;
    height: 48px;
    margin-bottom: 2px;
    padding: 20px 25px 6px 0;
    width: 100%;

    &:focus {
      border: solid 1px #1d9eff;
      box-shadow: 0 2.5px 5px 0 rgba(73, 174, 250, 0.2);
      padding-top: 20px;
    }

    &:-webkit-autofill {
      transition: background-color 1000s ease-in-out 0s;
    }

    &::placeholder {
      color: transparent;
      font-family: VoltePlay_Medium, sans-serif;
      position: relative;
      z-index: -1;
    }

    &:placeholder-shown {
      & ~ .floating-label {
        color: $light-font;
        cursor: text;
        font-size: 16px;
        top: 15px;
        left: 45px;
        text-transform: uppercase;
      }
    }

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      background-color: #f3e9e9;
      box-shadow: none;
      color: #6e655c;

      @include break-point(mobile-view) {
        background-color: #eeeae6;
      }
    }

    @include break-point(mobile-view) {
      font-size: 14px;
    }
  }

  .floating-label {
    pointer-events: none;
  }

  &.error {
    .input-element {
      border: 1px solid $red;
    }

    .invalid-text {
      color: $red;
      display: block;
      font-size: 12px;
      padding-right: 3px;
      text-align: right;
    }

    &.icon {
      &:after {
        top: 35%;
      }

      &.show-pwd {
        &:before {
          top: 35%;
        }
      }
    }
  }

  &.w-100{
    @include commonLabelProperty;

    .input-group-element {
      padding-bottom: 0;
    }
  }

  &.w-50 {
    position: relative;
    display: inline-block;
    margin-right: 15px;
    width: calc(100%/2 - 7.5px);

    &.double-line {
      .input-group-element {
        padding-top: 37px;
      }
    }

    @include break-point(mobile-view) {
      width: 100%;
    }

    &:nth-child(2n+1){
      margin-right: 0;
    }

    @include commonLabelProperty;

    &.error {
      .invalid-text {
        position: absolute;
        bottom: -4px;
        right: 0;

        @include break-point(mobile-view) {
          bottom: -15px;
        }
      }
    }

    &.delivery {
      margin-right: 15px;
    }

    &.manpower {
      margin-right: 0;
    }

    &.partner {
      margin-right: 15px;
    }

    &.territory {
      margin-right: 0;
    }

    &.partner-code {
      margin-right: 15px;
    }

    &.virtual-code {
      margin-right: 0;
    }

    &.m-right-0 {
      margin-right: 0px;
    }

    &.m-right-15 {
      margin-right: 15px;
    }
  }

  &.w-37 {
    display: inline-block;
    width: 37.5%;
    margin-right: 12px;
    position: relative;

    @include break-point(mobile-view) {
      display: block;
      margin-bottom: 20px;
      width: 100%;
    }

    @include commonLabelProperty;

    &.error {
    &.multiline-error{
        .invalid-text{
            bottom: -20px;
        }
    }
      .invalid-text {
        position: absolute;
        bottom: -4px;
        right: 0;

        @include break-point(mobile-view) {
          bottom: -15px;
        }
      }
    }
  }

  &.w-20 {
    @extend .w-50;
    position: relative;
    width: 19.2%;
    margin-right: 0;

    @include break-point(mobile-view) {
      width: 100%;
    }

    &.error {
      .invalid-text {
        position: absolute;
        bottom: -5px;
        right: 0;
        min-width: 155px;

        @include break-point(mobile-view) {
          bottom: -15px;
        }
      }

      &.data-list-control {
        .invalid-text {
          bottom: -20px;
          min-width: 100px;
        }
      }
    }
  }

  &.w-33 {
    @include commonLabelProperty;
    display: inline-flex;
    flex-direction: column;
    margin-right: 6px;
    width: calc(100%/3 - 6px);

    .input-group-element {
      padding-bottom: 0;
    }

    @include break-point(mobile-view) {
      width: calc(100%/2 - 4px);

      &:nth-child(2n){
        margin-right: 0;
      }
    }

    &.mr0{
      margin-right: 0;
    }

    &.market-m {
      @include break-point(mobile-view) {
        margin-right: 8px;
      }
    }
  }

  &.w-25 {
    display: inline-block;
    margin-right: 8px;
    width: calc(100%/4);

    &:nth-child(4n){
      margin-right: 0;
    }

    @include commonLabelProperty;

    .input-group-element {
      padding-bottom: 0;
    }

    @include break-point(mobile-view) {
      width: 100%;
    }
  }

  &.bd-50 {
    display: inline-block;
    width: calc(100%/2 - 7.5px);
    margin-right: 7px;
    position: relative;

    @include break-point(mobile-view) {
      width: 100%;
    }

    &:nth-of-type(even){
      margin-right: 0;
    }

    @include commonLabelProperty;

    &.error {
      .invalid-text {
        position: absolute;
        bottom: -4px;
        right: 0;
      }
    }
  }

  &.radio-btn-group {
    .radio-group {
      border: 2px solid $white;
      border-radius: 5px;
      box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, .45);
      height: 48px;
      line-height: 48px;
      padding-left: 10px;

      .radio-field {
        .select-radio {
          top: 9px;

          @include break-point(mobile-view) {
            top: 10px;
          }
        }
      }
    }

    &.soft-copy-action {
      width: calc(100% - 10px);

      .input-group-element {
        margin-top: -17px;
      }
    }
  }

  .input-group-element {
    position: relative;

    &.firm {
      &:after {
        background-image: url('./../../../assets/images/firm-icon.png');
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
      }
    }

    &.phone {
      &:after {
        background-image: url('./../../../assets/images/mobile-icon.png');
        background-size: 12px 20px;
        height: 20px;
        width: 12px;
      }
    }

    &.email {
      &:after {
        background-image: url('./../../../assets/images/email-icon.png');
        background-size: 19px 14px;
        height: 14px;
        width: 19px;
      }
    }

    &.otp {
      &:after {
        background-image: url('./../../../assets/images/password-icon.png');
        background-size: 22px 10px;
        height: 10px;
        width: 22px;
      }

      .resend-otp {
        color: $link-color;
        cursor: pointer;
        font-family: VoltePlay_Medium, sans-serif;
        font-size: 16px;
        position: absolute;
        top: 13px;
        right: 15px;
      }
    }

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 48%;
      left: 15px;
      transform: translateY(-50%);
    }

    &.icon {
      .input-element {
        padding-left: 45px;
      }
    }

    .show-tooltip {
      .info-icon {
        line-height: 0;
        position: absolute;
        top: 48%;
        right: 13px;
        transform: translateY(-50%);

        &:hover {
          svg {
            path {
              fill: $pink-color;
            }
          }
        }
      }

      &:hover {
        .tooltip-element {
          display: block;
        }
      }
    }

    .label-title {
      color: $light-font;
      position: absolute;
      top: 0;
      left: 2px;

      .optional-text {
        color: rgba(159, 165, 183, .88);
        padding-left: 3px;
      }
    }
  }

  &.rupee {
    margin-right: 8px !important;

    .input-group-element {
      position: relative;

      &:before {
        content: '\20B9';
        font-size: 17px;
        position: absolute;
        top: 34px;
        left: 12px;

        @include break-point(mobile-view) {
          top: 28px;
        }
      }

      .input-element {
        padding-left: 25px;
      }
    }
  }

  &.select-control {
    position: relative;

    select {
      -webkit-appearance: none;
    }

    &:after {
      background: url("./../../images/dropdown-arrow.svg");
      content: '';
      height: 11px;
      position: absolute;
      top: 50px;
      right: 7px;
      width: 11px;

      @include break-point(mobile-view) {
        top: 43px;
        right: 3px;
      }
    }
  }

  &.textarea-field {
    position: relative;

    @include break-point(mobile-view) {
      margin-bottom: -5px;
    }

    .input-group-element {
      padding-top: 20px;

      .input-element {
        height: 65px;
        padding: 5px;
        resize: none;
      }
    }

    &.error {
      .invalid-text {
        margin-top: -5px;
      }
    }

    .rejection-comment {
      height: 58px;
      padding: 5px 10px;

      +.error-message {
        margin-top: -5px;
      }
    }

    .form-group {
      &.error {
        .rejection-comment {
          border: 1px solid $red;
        }
      }
    }
  }

  select {
    outline: none;
    background-color: $white;
  }
}

.input-group-element label.floating-label,
.input-group-element .input-element:focus ~ .floating-label {
  color: $light-font;
  display: block;
  font-size: 11px;
  position: absolute;
  top: 9px;
  left: 45px;
  text-transform: uppercase;
  transition: 0.2s;
}
